import React, { useState, useEffect } from 'react';
import axios from "axios";
import dateFormat from "dateformat";
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import * as config from '../../config.js'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '500px',
    },
  },
}));

export default function UserItemDialog(props) {

  const classes = useStyles();

  const [userName,setUserName] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userTeamName, setUserTeamName] = useState('');
  const [userLeagueName, setUserLeagueName] = useState('');
  const [userBirthDate,setUserBirthDate] = useState('');
  const [userPlusfriendName, setUserPlusfriendName] = useState('');
  const [userPlusfriendLink, setUserPlusfriendLink] = useState('');

  const handleNameChange = (event) => {
    setUserName(event.target.value);
  }
  const handlePhoneNumberChange = (event) => {
    setUserPhoneNumber(event.target.value);
  }
  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  }
  const handleTeamNameChange = (event) => {
    setUserTeamName(event.target.value);
  }
  const handleLeagueNameChange = (event) => {
    setUserLeagueName(event.target.value);
  }
  const handleBirthDateChange = (event) => {
    setUserBirthDate(event.target.value);
  }
  const handlePlusfriendNameChange = (event) => {
    setUserPlusfriendName(event.target.value);
  }
  const handlePlusfriendLinkChange = (event) => {
    setUserPlusfriendLink(event.target.value);
  }

  const insertRequestSend = () =>{

    const selectedDateString = dateFormat(userBirthDate, "yyyy-mm-dd");

    const access_token = localStorage.getItem('access_token');

    const url = config.API_SERVER_URI + "/autho/insert/member"
    const thisUserList = []
    axios.post(url, {
      member_name : userName,
      member_phone_number : userPhoneNumber,
      member_team_name : userTeamName,
      member_league_name : userLeagueName,
      member_birth_date : selectedDateString,
      member_kakao_name : userPlusfriendName,
      member_kakao_url : userPlusfriendLink
    },{
      headers : {
        'Authorization' : 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.log(error);
    }).then(function() {
      props.handleDialogClose();
      props.LoadUsers();
    });
  }

  return(
    <Dialog open={props.dialogOpen} onClose={props.handleDialogClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">유저 추가</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="name"
              label="이름"
              type="text"
              placeholder="홍길동"
              value={userName}
              onChange={handleNameChange}
              fullWidth
            />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="phonenumber"
              label="휴대폰번호"
              type="text"
              placeholder="010-1234-5678"
              value={userPhoneNumber}
              onChange={handlePhoneNumberChange}
              fullWidth
            />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="email"
              label="이메일"
              type="text"
              placeholder="support@unique-play.com"
              value={userEmail}
              onChange={handleEmailChange}
              fullWidth
            />
          </GridItem>
          <GridItem xs={6}>
            <TextField
              margin="dense"
              id="team_name"
              label="팀명"
              type="text"
              placeholder="유니크플레이"
              value={userTeamName}
              onChange={handleTeamNameChange}
              fullWidth
            />
          </GridItem>
          <GridItem xs={6}>
            <TextField
              margin="dense"
              id="league_name"
              label="리그명"
              type="text"
              placeholder="유니크플레이리그"
              value={userLeagueName}
              onChange={handleLeagueNameChange}
              fullWidth
            />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="birth_date"
              label="생년월일"
              type="text"
              placeholder="1999-12-1"
              value={userBirthDate}
              onChange={handleBirthDateChange}
              fullWidth
            />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="plusfriend_name"
              label="플친이름"
              type="text"
              value={userPlusfriendName}
              onChange={handlePlusfriendNameChange}
              fullWidth
            />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="plusfriend_link"
              label="플친링크"
              type="text"
              value={userPlusfriendLink}
              onChange={handlePlusfriendLinkChange}
              fullWidth
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleDialogClose} color="error">
          취소
        </Button>
        <Button onClick={insertRequestSend} color="info">
          추가
        </Button>
      </DialogActions>
    </Dialog>

  );
}
