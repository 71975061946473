import React, { useState, useEffect } from 'react';
import axios from "axios";
import dateFormat from "dateformat";
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import * as config from '../../config.js'

const useStyles = makeStyles((theme) => ({
  select_field_root: {
    paddingTop : "8px",
    paddingBottom : "8px",
    marginTop : "8px",
    marginBottom : "4px"
  },

}));

export default function PlayItemDialog(props) {

  const classes = useStyles();

  const [playType, setPlayType] = useState('용병');
  const [playDate, setPlayDate] = useState('');
  const [playLeagueType, setPlayLeagueType] = useState('');
  const [playField, setPlayField] = useState(0);
  const [playTeam1Name, setPlayTeam1Name] = useState('');
  const [playTeam2Name, setPlayTeam2Name] = useState('');
  const [playGameURL, setPlayGameURL] = useState('');

  const shortDateTimeToLong = (shortDateTime) =>{
    var dateTimeSplit = shortDateTime.split(" ")
    var dateTimeString = "20" + dateTimeSplit[0].substr(0,2)+"-"+dateTimeSplit[0].substr(2,2)+"-"+dateTimeSplit[0].substr(4) +" "
    dateTimeString += dateTimeSplit[1].substr(0,2) + ":"+dateTimeSplit[1].substr(2,4)+":00"
    return dateTimeString;
  }

  const handleTypeChange = (event) => {
    setPlayType(event.target.value);
  }

  const handleDateChange = (event) => {
    setPlayDate(event.target.value);
  }

  const handleLeagueTypeChange = (event) => {
    setPlayLeagueType(event.target.value);
  }

  const handleFieldChange = (event) => {
    setPlayField(event.target.value);
  }

  const handleTeam1NameChange = (event) => {
    setPlayTeam1Name(event.target.value);
  }

  const handleTeam2NameChange = (event) => {
    setPlayTeam2Name(event.target.value);
  }

  const handleGameURLChange = (event) => {
    setPlayGameURL(event.target.value);
  }

  const insertRequestSend = () =>{
    console.log("추가요청");
    var playDateTimeString = shortDateTimeToLong(playDate)
    const thisDateTime = new Date();
    const thisDateTimeString = dateFormat(thisDateTime, "yyyy-mm-dd HH:mm:ss");
    console.log(playDateTimeString);
    console.log(thisDateTimeString);

    const url = config.API_SERVER_URI + "/insert/play"
    const thisUserList = []
    axios.post(url, {
      play_type : playType,
      play_date : playDateTimeString,
      play_league_type : playLeagueType,
      play_field : props.fieldList[playField],
      play_team_name1 : playTeam1Name,
      play_team_name2 : playTeam2Name,
      play_game_url : playGameURL,
      play_input_date : thisDateTimeString
    })
    .then(function (response) {
      console.log(response);
      console.log(response.data);
    }).catch(function (error) {
      console.log(error);
    }).then(function() {
      props.handleItemDialogClose();
      props.LoadPlays();
    });
  }

  const fieldMenuItems = props.fieldList.map((field,i) => {
    return (
      <MenuItem value={i}>{field}</MenuItem>
    )
  })

  return(
    <Dialog open={props.playItemDialogOpen} onClose={props.handleItemDialogClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">경기 추가</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12}>
            <Select
              value={playType}
              onChange={handleTypeChange}
              fullWidth
            >
              <MenuItem value={'용병'}>용병</MenuItem>
              <MenuItem value={'리그'}>리그</MenuItem>
            </Select>
          </GridItem>
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="date"
              label="날짜"
              type="text"
              placeholder="210526 1900"
              value={playDate}
              onChange={handleDateChange}
              fullWidth
            />
          </GridItem>
          { playType == "리그" &&
            <GridItem xs={12}>
              <TextField
                margin="dense"
                id="phonenumber"
                label="리그 종류"
                type="text"
                placeholder="평일야간 혜성조"
                value={playLeagueType}
                onChange={handleLeagueTypeChange}
                fullWidth
              />
            </GridItem>
          }
          <GridItem xs={12}>
            <Select
              value={playField}
              onChange={handleFieldChange}
              fullWidth
              classes={{
                root: classes.select_field_root
              }}
            >
              {fieldMenuItems}
            </Select>
          </GridItem>
          { playType == "리그" &&
          <GridItem xs={6}>
            <TextField
              margin="dense"
              id="league_name"
              label="팀명1"
              type="text"
              placeholder="선공팀"
              value={playTeam1Name}
              onChange={handleTeam1NameChange}
              fullWidth
            />
          </GridItem>
          }
          { playType == "리그" &&
          <GridItem xs={6}>
            <TextField
              margin="dense"
              id="number"
              label="팀명2"
              placeholder="후공팀"
              value={playTeam2Name}
              onChange={handleTeam2NameChange}
              type="text"
              fullWidth
            />
          </GridItem>
          }
          { playType == "리그" &&
            <GridItem xs={12}>
              <TextField
                margin="dense"
                id="plusfriend_name"
                label="게임원 주소"
                type="text"
                placeholder="http://www.gameone.kr/league/schedule/content/boxscore?lig_idx=10663&game_idx=1270275&group_code=0&outside="
                value={playGameURL}
                onChange={handleGameURLChange}
                fullWidth
              />
            </GridItem>
          }
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleItemDialogClose} color="error">
          취소
        </Button>
        <Button onClick={insertRequestSend} color="info">
          추가
        </Button>
      </DialogActions>
    </Dialog>

  );
}
