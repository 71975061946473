import React, { useState, useEffect } from 'react';
import axios from "axios";
import dateFormat from "dateformat";
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import * as config from '../../config.js'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '500px',
    },
  },
}));

export default function AccountingItemDialog(props) {

  const classes = useStyles();

  const [accountingType, setAccountingType] = useState('');
  const [accountingDate, setAccountingDate] = useState('');
  const [accountingInfo, setAccountingInfo] = useState('');
  const [accountingMoneyIn, setAccountingMoneyIn] = useState(0);
  const [accountingMoneyOut, setAccountingMoneyOut] = useState(0);

  const handleTypeChange = (event) => {
    setAccountingType(event.target.value);
  }

  const handleDateChange = (event) => {
    setAccountingDate(event.target.value);
  }
  const handleInfoChange = (event) => {
    setAccountingInfo(event.target.value);
  }
  const handleMoneyInChange = (event) => {
    setAccountingMoneyIn(event.target.value);
  }
  const handleMoneyOutChange = (event) => {
    setAccountingMoneyOut(event.target.value);
  }

  const insertRequestSend = () =>{
    console.log("추가요청");

    const access_token = localStorage.getItem('access_token');
    const url = config.API_SERVER_URI + "/autho/insert/accounting"
    axios.post(url, {
      accounting_type : accountingType,
      accounting_date : accountingDate,
      accounting_info : accountingInfo,
      accounting_money_in : accountingMoneyIn,
      accounting_money_out : accountingMoneyOut,
    },{
      headers : {
        'Authorization' : 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.log(error);
    }).then(function() {
      props.handleItemDialogClose();
      props.LoadAccountings();
    });
  }

  return(
    <Dialog className={classes.root} open={props.accountingDialogOpen} onClose={props.handleItemDialogClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">거래 내역 추가</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="type"
              label="구분"
              type="text"
              placeholder="교통비"
              value={accountingType}
              onChange={handleTypeChange}
              fullWidth
            />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="date"
              label="날짜"
              type="text"
              placeholder="2021-01-01 18:36:45"
              value={accountingDate}
              onChange={handleDateChange}
              fullWidth
            />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="info"
              label="내용"
              type="text"
              placeholder="야구장 교통비"
              value={accountingInfo}
              onChange={handleInfoChange}
              fullWidth
            />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="money_in"
              label="수입"
              type="text"
              placeholder="0"
              value={accountingMoneyIn}
              onChange={handleMoneyInChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">￦</InputAdornment>,
              }}
              fullWidth
            />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="money_out"
              label="비용"
              type="text"
              placeholder="40000"
              value={accountingMoneyOut}
              onChange={handleMoneyOutChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">￦</InputAdornment>,
              }}
              fullWidth
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleItemDialogClose} color="error">
          취소
        </Button>
        <Button onClick={insertRequestSend} color="info">
          추가
        </Button>
      </DialogActions>
    </Dialog>

  );
}
