import React, { useState, useEffect } from 'react';
import bcrypt from "bcryptjs";
import axios from "axios";
import { Switch, Route, Redirect } from "react-router-dom";
import LibraryBooks from "@material-ui/icons/LibraryBooks";

import TextField from '@material-ui/core/TextField';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";

export default function Signup() {
  const [signupID, setSignupID] = useState('');
  const [signupPW, setSignupPW] = useState('');
  const [signupPWCheck, setSignupPWCheck] = useState('');
  const [signupName, setSignupName] = useState('');
  const [signupPhoneNumber, setSignupPhoneNumber] = useState('');

  const handleIDChange = (event) => {
    setSignupID(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setSignupPW(event.target.value);
  }

  const handlePasswordCheckChange = (event) => {
    setSignupPWCheck(event.target.value);
  }

  const handlePhoneNumberChange = (event) => {
    setSignupPhoneNumber(event.target.value);
  }

  const handleNameChange = (event) => {
    setSignupName(event.target.value);
  }

  const isPhonenubmerOk = (asValue) => {
      var regExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;
      return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
  }

  const isPasswordOk = (asValue) => {
      var regExp = /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/; //8~15 영문 특문 숫자 포함

      return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
  }

  const insertAdminSend = () =>{

    const salt = bcrypt.genSaltSync(10);
    var hash = bcrypt.hashSync(signupPW, salt);
    var hash2 = bcrypt.hashSync(signupPWCheck, salt);
    console.log(signupPW == signupPWCheck);
    if(signupPW != signupPWCheck){
      alert("두 비밀번호가 다릅니다.");
      return;
    }
    if(signupID == "" || signupPW == "" || signupPWCheck == "" || signupName == "" || signupPhoneNumber == ""){
      alert("필드를 다 채워주세요.")
      return;
    }

    if(isPasswordOk(signupPW) == false){
      alert("올바르지않은 비밀번호입니다.");
      return;
    }
    if(isPhonenubmerOk(signupPhoneNumber) == false){
      alert("올바르지않은 휴대폰번호입니다.");
      return;
    }

    const url = "https://api.unique-play.com/insert/admin"

    axios.post(url, {
      admin_login_id : signupID,
      admin_login_password : hash,
      admin_salt_key : salt,
      admin_name : signupName,
      admin_phone_number : signupPhoneNumber
    })
    .then(function (response) {
      console.log(response.data);
      window.location.reload();
    }).catch(function (error) {
      console.log(error);
    }).then(function() {
    });

  }

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="rose">
            <LibraryBooks />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <TextField
                margin="dense"
                label="아이디"
                type="text"
                value={signupID}
                onChange={handleIDChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                margin="dense"
                label="비밀번호"
                type="password"
                value={signupPW}
                onChange={handlePasswordChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                margin="dense"
                label="비밀번호 확인"
                type="password"
                value={signupPWCheck}
                onChange={handlePasswordCheckChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                margin="dense"
                label="이름"
                type="text"
                value={signupName}
                onChange={handleNameChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                margin="dense"
                label="휴대폰번호"
                type="text"
                value={signupPhoneNumber}
                onChange={handlePhoneNumberChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12}>
              <Button fullWidth onClick={insertAdminSend} color="success" autoFocus>
                회원가입
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

    </div>
  );
}
