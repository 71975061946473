import React, { useState, useEffect, useMemo } from 'react';
import axios from "axios";
import dateFormat from "dateformat";
import DataGrid, { SelectColumn, TextEditor, SelectCellFormatter } from 'react-data-grid';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListAltIcon from '@material-ui/icons/ListAlt';
import TextField from '@material-ui/core/TextField';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import PlayItemDialog from "components/Dialog/PlayItemDialog.js";
import PlayMultiItemDialog from "components/Dialog/PlayMultiItemDialog.js";
import PlayEndDialog from "components/Dialog/PlayEndDialog.js";
import PlayExcelDialog from "components/Dialog/PlayExcelDialog.js";
import * as config from '../../config.js'

const styles = {
  addButton: {
    float : "right",
    marginRight : "15px"
  },
  dataGrid: {
    minHeight : "540px",
    textAlign : "center"
  },
  highlightRow : {
    backgroundColor : "#ebebeb",
    '&:hover': {
       backgroundColor : "#f2f2f2",
    },
  }
};

const useStyles = makeStyles(styles);
const week = ['일', '월', '화', '수', '목', '금', '토'];
const fieldList = [
  "수원대 구장", "M.C Baseball Park","오산TS야구장","명품BB파크야구장","파워리그구장",
  "경희대 구장","유신고등학교 야구장","JS베이스볼파크","우리은행야구장","동탄야구장",
  "청학광형베이스볼파크","훼릭스1구장","훼릭스2구장","부천2구장","오산시야구장","인재원야구장",
  "전체"
]

const columns = [
    { key: "index", name: "순번", width: 60 , frozen: true },
    { key: "type", name: "구분", width: 100 },
    { key: "date", name: "날짜", editor: TextEditor },
    { key: "league_type", name: "리그 구분", editor: TextEditor },
    { key: "field", name: "구장명", editor: TextEditor },
    { key: "team_name1", name: "팀1", editor: TextEditor },
    { key: "team_name2", name: "팀2", editor: TextEditor },
    { key: "game_url", name: "게임원" },
    { key: "submit", name: "완료" }
];

const new_columns = [
    { key: "index", name: "순번", width: 10 , frozen: true },
    { key: "type", name: "구분", width: 70 },
    { key: "date", name: "날짜", width: 200, editor: TextEditor },
    { key: "league_type", name: "리그 구분", editor: TextEditor },
    { key: "field", name: "구장명", width: 150, editor: TextEditor },
    { key: "team_name1", name: "팀1", editor: TextEditor },
    { key: "team_name2", name: "팀2", editor: TextEditor },
    { key: "game_url", name: "게임원" },
    { key: "submit", name: "전체완료" },
    { key: "youtube_done", name: "유타댓알" },
    { key: "hot_done", name: "핫클립" },
    { key: "hr_done", name: "홈런" },
    { key: "gameone_done", name: "게임원댓"}
];


export default function PlayList() {
  const classes = useStyles();
  const [rows, setRows] = useState([])
  const [sortColumns, setSortColumns] = useState([]);
  const [playList, setPlayList] = useState([]);
  const [playItemDialogOpen,setPlayItemDialogOpen] = useState(false);
  const [playMultiEndDialogOpen,setPlayMultiEndDialogOpen] = useState(false);
  const [playEndDialogOpen,setPlayEndDialogOpen] = useState(false);
  const [playExcelDialogOpen,setPlayExcelDialogOpen] = useState(false);
  const [playEndIndex, setPlayEndIndex] = useState(0);
  const [infoIndexDict, setInfoIndexDict] = useState({});

  const timestampToDateString = (timestamp) =>{
    const datetime = new Date(timestamp)
    const dateString = dateFormat(datetime, "yy년mm월dd일("+ week[datetime.getDay()]+") HH:MM");
    return dateString;
  }

  const dateStringToTimestamp = (datestring) =>{
    var datestring_split = datestring.split(' ')
    var timestamp = "20" + datestring_split[0].substr(0,2) + "-" + datestring_split[0].substr(3,2) + "-" + datestring_split[0].substr(6,2) + " "
    timestamp += datestring_split[1] + ":00"
    return timestamp
  }

  const handleItemDialogOpen = () => {
      setPlayItemDialogOpen(true);
    };

  const handleItemDialogClose = () => {
    setPlayItemDialogOpen(false);
  };

  const handleExcelDialogOpen = () => {
    setPlayExcelDialogOpen(true);
  }

  const handleExcelDialogClose = () => {
    setPlayExcelDialogOpen(false);
  }

  const handleMultiEndDialogOpen = (event) => {
    setPlayMultiEndDialogOpen(true);
  };

  const handleMultiEndDialogClose = () => {
    setPlayMultiEndDialogOpen(false);
  };

  const handleEndDialogOpen = () => {
    setPlayEndDialogOpen(true);
  };

  const handleEndDialogClose = () => {
    setPlayEndDialogOpen(false);
  };

  // 경기 완료 함수
  const handleFunctionClick = (event) => {
    console.log("end");
    setPlayEndIndex(event.currentTarget.value);
    handleEndDialogOpen();
  }

  const tokenRefresh = () => {
    const refresh_token = localStorage.getItem('refresh_token');
    const url = config.API_SERVER_URI + "/reissueToken";

    axios.post(url, {},{
      headers : {
        'Authorization' : 'Bearer ' + refresh_token
      }
    })
    .then(function (response) {
      if(response.data.response == true){
        //토큰 업데이트
        localStorage.setItem('access_token',response.data.accesss_token);
        if(response.data.refresh_token){
          localStorage.setItem('refresh_token',response.data.refresh_token);
        }
      }
      else{
        alert("재로그인이 필요합니다.");
        window.location.href="/login"
      }

    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
    });
  }
  const LoadPlays = () =>{
    const access_token = localStorage.getItem('access_token');
    const url = config.API_SERVER_URI + "/autho/select/play"
    const thisPlayList = []
    const thisRows = []
    const thisInfoIndexDict= {}

    axios.post(url, {
    },{
      headers : {
        'Authorization' : 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      //console.log(response.data);

      //완료된것과 완료되지 않은것을 나눠서 정렬한다.
      var nullPlayList = []
      var notNullPlayList =[]
      var totalPlayList = []

      for(const index in response.data){
        const this_data = response.data[index];

        if(this_data.play_end_date == null){
          nullPlayList.push(this_data)
        }
        else{
          notNullPlayList.push(this_data)
        }
      }
      //완료되지 않은 것 정렬
      nullPlayList.sort(
        function (v1, v2) {
          const v1DateString = dateFormat(new Date(v1.play_date),"yy-mm-dd")
          const v2DateString = dateFormat(new Date(v2.play_date),"yy-mm-dd")

          if(v1.play_field < v2.play_field && v1DateString <= v2DateString){
            return -1
          }
        }
      );
      //완료된 것 정렬
      notNullPlayList.sort(
        function (v1, v2) {
          const v1DateString = dateFormat(new Date(v1.play_date),"yy-mm-dd")
          const v2DateString = dateFormat(new Date(v2.play_date),"yy-mm-dd")

          if(v1.play_field < v2.play_field && v1DateString <= v2DateString){
            return -1
          }
        }
      );

      //null인것과 아닌것 합치기
      totalPlayList = nullPlayList.concat(notNullPlayList)

      var count = 1
      for(const index in totalPlayList){
        const this_data = totalPlayList[index];
        thisInfoIndexDict[this_data['play_index']] = this_data
        thisPlayList.push([count,this_data['play_type'],timestampToDateString(this_data['play_date']),
          this_data['play_league_type'],this_data['play_field'],
          this_data['play_team_name1'],this_data['play_team_name2'],
          <div>
            {
              (this_data['play_game_url'] == null || this_data['play_game_url'] == "") ?
              "" :
              <a href={this_data['play_game_url']} target='_blank'>게임원</a>
            }
          </div>
          ,
          <div>
            {
              this_data['play_end_date'] == null ?
              <Button color="success" value={this_data['play_index']} onClick={handleFunctionClick}>완료</Button>
              :
              <Button color="success" disabled>완료</Button>
            }
          </div>
        ])

        var this_row = {
          index :count,
          type:this_data['play_type'],
          date: timestampToDateString(this_data['play_date']),
          league_type:this_data['play_league_type'],
          field: this_data['play_field'],
          team_name1: this_data['play_team_name1'],
          team_name2: this_data['play_team_name2'],
          game_url:
          <div>
            {
              (this_data['play_game_url'] == null || this_data['play_game_url'] == "") ?
              "" :
              <a href={this_data['play_game_url']} target='_blank'>게임원</a>
            }
          </div>,
          submit:
          <div>
            {
              this_data['play_end_date'] == null ?
              <Button color="success" value={this_data['play_index']} onClick={handleFunctionClick} >완료</Button>
              :
              <Button color="success" disabled>완료</Button>
            }
          </div>,
          youtube_done : this_data['play_youtube_done'] == null ? "" : this_data['play_youtube_done'],
          hot_done : this_data['play_hot_done'] == null ? "" : this_data['play_hot_done'],
          hr_done : this_data['play_hr_done'] == null ? "" : this_data['play_hr_done'],
          gameone_done : this_data['play_gameone_done'] == null ? "" : this_data['play_gameone_done'],
          play_index : this_data['play_index'],
          is_end : this_data['play_end_date'] == null ? false : true
        }

        thisRows.push(this_row)

        count+=1
      }

    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
        setPlayList(thisPlayList)
        setInfoIndexDict(thisInfoIndexDict)
        setRows(thisRows)
    });
  }

  useEffect(() => {

    const access_token = localStorage.getItem('access_token');
    if(access_token == null || access_token == undefined){
      alert("로그인이 필요합니다.")
      window.location.href="/login"
    }
    else{
      tokenRefresh();
    }

    LoadPlays();

  }, [])

  const rowKeyGetter = (row) =>{
    return row.id;
  }

  const onRowsChange = (changed_rows) =>{
    //console.log(changed_rows);
    for(var i in changed_rows){
      if(rows[i] != changed_rows[i]){
        console.log(rows[i]);
        console.log(changed_rows[i]);

        const access_token = localStorage.getItem('access_token');
        const url = config.API_SERVER_URI+"/autho/update/play/withindex"
        axios.post(url, {
          play_index : changed_rows[i].play_index,
          play_type : changed_rows[i].type,
          play_date : dateStringToTimestamp(changed_rows[i].date),
          play_league_type : changed_rows[i].league_type,
          play_field : changed_rows[i].field,
          play_team_name1 : changed_rows[i].team_name1,
          play_team_name2 : changed_rows[i].team_name2
        },{
          headers : {
            'Authorization' : 'Bearer ' + access_token
          }
        })
        .then(function (response) {
        }).catch(function (error) {
            // 오류발생시 실행
            console.log(error);
        }).then(function() {
            // 항상 실행
        });
      }
    }
    setRows(changed_rows)
  }

  function getComparator(sortColumn): Comparator {
    switch (sortColumn) {
      case 'type':
      case 'date':
      case 'league_type':
      case 'field':
      case 'team_name1':
      case 'team_name2':
        return (a, b) => {
          return a[sortColumn].localeCompare(b[sortColumn]);
        };
      case 'index':
        return (a, b) => {
          return a[sortColumn] - b[sortColumn];
        };
      default:
        return (a, b) => {
          return 0;
        };
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  }

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return rows;

    const sortedRows = [...rows];
    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
    return sortedRows;
  }, [rows, sortColumns]);

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="rose">
            <ListAltIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <div style={{display:"inline-block",width:"100%",height:"60px"}}>
            <Button className={classes.addButton} type="button" color="info" onClick={handleItemDialogOpen}>추가</Button>
            <Button className={classes.addButton} type="button" color="success" onClick={handleMultiEndDialogOpen}>다중 완료</Button>
            <Button className={classes.addButton} type="button" color="warning" onClick={handleExcelDialogOpen}>엑셀 뽑기</Button>
          </div>
          <div style={{height:"80%"}}>
            <DataGrid
              columns={new_columns}
              rowGetter={rowKeyGetter}
              rowsCount={100}
              rows={sortedRows}
              defaultColumnOptions={{
                    sortable: true,
                    resizable: true
                  }}
              onRowsChange={onRowsChange}
              sortColumns={sortColumns}
              onSortColumnsChange={setSortColumns}
              rowHeight={55}
              className={classes.dataGrid}
              rowClass={(row) => (row.is_end == true ? classes.highlightRow : undefined)}
            />
          </div>
          {/*
          <Table
            tableHeaderColor="primary"
            tableHead={["순번","구분", "날짜", "리그 구분", "구장명", "팀1", "팀2", "게임원 링크","완료"]}
            tableData={playList}
          />
          */}
        </CardBody>
      </Card>
      <PlayItemDialog
        fieldList={fieldList}
        playItemDialogOpen={playItemDialogOpen}
        handleItemDialogClose={handleItemDialogClose}
        LoadPlays={LoadPlays}
      />
      <PlayMultiItemDialog
        fieldList={fieldList}
        playMultiEndDialogOpen={playMultiEndDialogOpen}
        handleMultiEndDialogClose={handleMultiEndDialogClose}
        LoadPlays={LoadPlays}
      />
      <PlayEndDialog
        playEndDialogOpen={playEndDialogOpen}
        playEndIndex={playEndIndex}
        handleEndDialogClose={handleEndDialogClose}
        infoIndexDict={infoIndexDict}
        LoadPlays={LoadPlays}
      />
      <PlayExcelDialog
        fieldList={fieldList}
        playList={playList}
        playExcelDialogOpen={playExcelDialogOpen}
        handleExcelDialogOpen={handleExcelDialogOpen}
        handleExcelDialogClose={handleExcelDialogClose}
      />

    </div>

  );
}
