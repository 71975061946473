import React, { useState, useEffect } from 'react';
import axios from "axios";
import dateFormat from "dateformat";
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import * as config from '../../config.js'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '500px',
    },
  },
}));

export default function PlayMultiItemDialog(props) {

  const classes = useStyles();
  const [playField, setPlayField] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleFieldChange = (event) => {
    setPlayField(event.target.value);
  }

  const endRequestSend = () =>{
    console.log("완료요청");

    const access_token = localStorage.getItem('access_token');
    const url = config.API_SERVER_URI + "/autho/update/play/many"
    const thisUserList = []
    axios.post(url, {
      play_date : dateFormat(selectedDate, "yyyy-mm-dd"),
      play_field : props.fieldList[playField],
    },{
      headers : {
        'Authorization' : 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.log(error);
    }).then(function() {
      props.handleMultiEndDialogClose();
      props.LoadPlays();
    });

  }

  const fieldMenuItems = props.fieldList.map((field,i) => {
    return (
      <MenuItem value={i}>{field}</MenuItem>
    )
  })

  return(
    <Dialog className={classes.root} open={props.playMultiEndDialogOpen} onClose={props.handleMultiEndDialogClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">경기 다중 완료</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12}>
            <Select
              value={playField}
              onChange={handleFieldChange}
              fullWidth
            >
              {fieldMenuItems}
            </Select>
          </GridItem>
          <GridItem xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Date picker inline"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleMultiEndDialogClose} color="error">
          취소
        </Button>
        <Button onClick={endRequestSend} color="info">
          완료
        </Button>
      </DialogActions>
    </Dialog>

  );
}
