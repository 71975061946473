import React, { useState, useEffect } from 'react';
import axios from "axios";
import dateFormat from "dateformat";
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import * as config from '../../config.js'

const useStyles = makeStyles((theme) => ({
  select_field_root: {
    paddingTop : "8px",
    paddingBottom : "8px",
    marginTop : "8px",
    marginBottom : "4px"
  },
  multi_field_root: {
    paddingTop : "8px",
    paddingBottom : "8px",
    marginTop : "8px",
    marginBottom : "4px"
  },
  admit_select_root: {
    paddingRight : "0px",
    paddingTop : "8px",
    paddingBottom : "8px",
    marginTop : "10px",
    marginBottom : "4px"
  },
}));

export default function ApplyItemDialog(props) {

  const classes = useStyles();

  const [applyType, setApplyType] = useState('homerun');
  const [applySubType, setApplySubType] = useState('full');
  const [applyPlayDate, setApplyPlayDate] = useState('');
  const [applyHitTime, setApplyHitTime] = useState('');
  const [applyField, setApplyField] = useState('');
  const [applyTeamName, setApplyTeamName] = useState('');
  const [applyUserName, setApplyUserName] = useState('');
  const [applyUserPosition, setApplyUserPosition] = useState('');
  const [applyInningInfo, setApplyInningInfo] = useState('');
  const [applyBattingOrder, setApplyBattingOrder] = useState('');
  const [applyUserNumber, setApplyUserNumber] = useState('');
  const [applyDescription, setApplyDescription] = useState('');
  const [applyDepositorName, setApplyDepositorName] = useState('');
  const [applyDepositorPhoneNumber, setApplyDepositorPhoneNumber] = useState('');
  const [applyIsPublic, setApplyIsPublic] = useState('admit');

  const shortDateTimeToLong = (shortDateTime) =>{
    var dateTimeSplit = shortDateTime.split(" ")
    var dateTimeString = "20" + dateTimeSplit[0].substr(0,2)+"-"+dateTimeSplit[0].substr(2,2)+"-"+dateTimeSplit[0].substr(4) +" "
    dateTimeString += dateTimeSplit[1].substr(0,2) + ":"+dateTimeSplit[1].substr(2,4)+":00"
    return dateTimeString;
  }

  const handleTypeChange = (event) => {
    setApplyType(event.target.value);
  }
  const handleSubTypeChange = (event) => {
    setApplySubType(event.target.value);
  }

  const handleDateChange = (event) => {
    setApplyPlayDate(event.target.value);
  }
  const handleHitTimeChange = (event) => {
    setApplyHitTime(event.target.value);
  }
  const handleFieldChange = (event) => {
    setApplyField(event.target.value);
  }
  const handleTeamNameChange = (event) => {
    setApplyTeamName(event.target.value);
  }
  const handleUserNameChange = (event) => {
    setApplyUserName(event.target.value);
  }
  const handleUserPositionChange = (event) => {
    setApplyUserPosition(event.target.value);
  }
  const handleInningInfoChange = (event) => {
    setApplyInningInfo(event.target.value);
  }
  const handleBattingOrderChange = (event) => {
    setApplyBattingOrder(event.target.value);
  }
  const handleUserNumberChange = (event) => {
    setApplyUserNumber(event.target.value);
  }
  const handleDescriptionChange = (event) => {
    setApplyDescription(event.target.value);
  }

  const handleDepositorNameChange = (event) => {
    setApplyDepositorName(event.target.value);
  }
  const handleDepositorPhoneNumberChange = (event) => {
    setApplyDepositorPhoneNumber(event.target.value);
  }
  const handleIsPulbicChange = (event) => {
    setApplyIsPublic(event.target.value);
  }

  const insertRequestSend = () =>{
    console.log("추가요청");
    var url = ""
    var data = {}
    var header = {}
    const access_token = localStorage.getItem('access_token');
    if(applyType == "homerun"){
      url = config.API_SERVER_URI + "/autho/insert/homerun"
      data = {
        homerun_play_date :shortDateTimeToLong(applyPlayDate),
        homerun_hit_time : applyHitTime,
        homerun_field : applyField,
        homerun_team_name : applyTeamName,
        homerun_user_name : applyUserName,
        homerun_user_position : applyUserPosition,
        homerun_description : applyDescription
      }
      header = {
        headers : {
          'Authorization' : 'Bearer ' + access_token
        }
      }

    }
    else if(applyType == "apply"){
      url = config.API_SERVER_URI + "/insert/apply"
      data = {
        type: applySubType,
        date: shortDateTimeToLong(applyPlayDate),
        ground_name:applyField,
        team_name:applyTeamName,
        player_name:applyUserName,
        player_position:applyUserPosition,
        target_inning : applyInningInfo,
        batter_number:applyBattingOrder,
        player_number:applyUserNumber,
        bat_detail:applyDescription,
        depositor_name:applyDepositorName,
        phone_number: applyDepositorPhoneNumber,
        is_admit_disclose: applyIsPublic

      }
    }

    axios.post(url, data,header)
    .then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.log(error);
    }).then(function() {
      props.handleItemDialogClose();
      if(applyType == "homerun"){
        props.LoadHomeruns();
      }
      else if(applyType == "apply"){
        props.LoadApplys();
      }
    });

  }

  return(
    <Dialog open={props.applyItemDialogOpen} onClose={props.handleItemDialogClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">경기 추가</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12}>
            <Select
              value={applyType}
              onChange={handleTypeChange}
              fullWidth
              classes={{
                root: classes.select_field_root
              }}
            >
              <MenuItem value={'homerun'}>홈런</MenuItem>
              <MenuItem value={'apply'}>신청영상</MenuItem>
            </Select>
          </GridItem>
          { applyType=="apply" &&
            <GridItem xs={12}>
              <Select
                value={applySubType}
                onChange={handleSubTypeChange}
                fullWidth
                classes={{
                  root: classes.select_field_root
                }}
              >
                <MenuItem value={'full'}>풀영상</MenuItem>
                <MenuItem value={'single'}>한타석</MenuItem>
                <MenuItem value={'all'}>전타석</MenuItem>
                <MenuItem value={'pitcher'}>투수</MenuItem>
              </Select>
            </GridItem>
          }
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="date"
              label="날짜"
              type="text"
              placeholder="210526 1900"
              value={applyPlayDate}
              onChange={handleDateChange}
              fullWidth
            />
          </GridItem>
          { applyType=="homerun" &&
            <GridItem xs={12}>
              <TextField
                margin="dense"
                id="hittime"
                label="타격시간"
                type="text"
                placeholder="19:28"
                value={applyHitTime}
                onChange={handleHitTimeChange}
                fullWidth
              />
            </GridItem>
          }
          <GridItem xs={12}>
            <TextField
              margin="dense"
              id="field"
              label="구장명"
              type="text"
              placeholder="수원대 구장"
              value={applyField}
              onChange={handleFieldChange}
              fullWidth
            />
          </GridItem>
          <GridItem xs={4}>
            <TextField
              margin="dense"
              id="team_name"
              label="팀명"
              type="text"
              placeholder="LA다저스"
              value={applyTeamName}
              onChange={handleTeamNameChange}
              fullWidth
            />
          </GridItem>
          <GridItem xs={4}>
            <TextField
              margin="dense"
              id="position"
              label="포지션"
              type="text"
              placeholder="P"
              value={applyUserPosition}
              onChange={handleUserPositionChange}
              fullWidth
            />
          </GridItem>
          <GridItem xs={4}>
            <TextField
              margin="dense"
              id="user_name"
              label="유저명"
              type="text"
              placeholder="류현진"
              value={applyUserName}
              onChange={handleUserNameChange}
              fullWidth
            />
          </GridItem>
          { applyType=="apply" &&
            <React.Fragment>
              <GridItem xs={4}>
                <TextField
                  margin="dense"
                  id="inning_info"
                  label="이닝정보"
                  type="text"
                  placeholder="1회말 2아웃"
                  value={applyInningInfo}
                  onChange={handleInningInfoChange}
                  fullWidth
                />
              </GridItem>
              <GridItem xs={4}>
                <TextField
                  margin="dense"
                  id="batting_order"
                  label="타순"
                  type="text"
                  placeholder="4"
                  value={applyBattingOrder}
                  onChange={handleBattingOrderChange}
                  fullWidth
                />
              </GridItem>
              <GridItem xs={4}>
                <TextField
                  margin="dense"
                  id="user_number"
                  label="등번호"
                  type="text"
                  placeholder="99"
                  value={applyUserNumber}
                  onChange={handleUserNumberChange}
                  fullWidth
                />
              </GridItem>
            </React.Fragment>
          }
          <GridItem xs={12}>
            <TextField
              label="추가 정보"
              multiline
              value={applyDescription}
              onChange={handleDescriptionChange}
              rows={4}
              variant="outlined"
              fullWidth
              classes={{
                root: classes.multi_field_root
              }}
            />
          </GridItem>

          { applyType=="apply" &&
            <React.Fragment>
              <GridItem xs={4}>
                <TextField
                  margin="dense"
                  id="inning_info"
                  label="입금자명"
                  type="text"
                  placeholder="류현진"
                  value={applyDepositorName}
                  onChange={handleDepositorNameChange}
                  fullWidth
                />
              </GridItem>
              <GridItem xs={6}>
                <TextField
                  margin="dense"
                  id="batting_order"
                  label="휴대폰번호"
                  type="text"
                  placeholder="010-1234-5678"
                  value={applyDepositorPhoneNumber}
                  onChange={handleDepositorPhoneNumberChange}
                  fullWidth
                />
              </GridItem>
              <GridItem xs={2}>
                <Select
                  value={applyIsPublic}
                  onChange={handleIsPulbicChange}
                  fullWidth
                  classes={{
                    root: classes.admit_select_root
                  }}
                >
                  <MenuItem value={'admit'}>공개</MenuItem>
                  <MenuItem value={'disadmit'}>비공개</MenuItem>
                </Select>
              </GridItem>
            </React.Fragment>
          }


        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleItemDialogClose} color="error">
          취소
        </Button>
        <Button onClick={insertRequestSend} color="info">
          추가
        </Button>
      </DialogActions>
    </Dialog>

  );
}
