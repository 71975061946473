import React from "react";
import classNames from "classnames";
import jwt_decode from "jwt-decode";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Filter5 from "@material-ui/icons/Filter5";
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { Filter9Plus } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const viewProfile = () =>{
    var token = localStorage.getItem('access_token');
    var decoded = jwt_decode(token);

    console.log(decoded);
    alert("안녕하세요. "+decoded.login_id)
    setOpenProfile(null);
  }

  const logOut = () => {

    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    alert("로그아웃 되었습니다.")
    window.location.href="/login"
  }

  const gotoPlusFriend = () => {
    let path = `https://center-pf.kakao.com/_usYyT/dashboard`;
    //document.location.href = path
    window.open(path, "_blank")
  }
  const gotoGameonePage = (lig_idx) => {
    let path = `http://www.gameone.kr/league/?lig_idx=`+lig_idx;
    //document.location.href = path
    window.open(path, "_blank")
  }

  return (
    <div>
      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
        onClick={gotoPlusFriend}
      >
        <ChatBubbleOutlineIcon className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>플러스 친구</p>
        </Hidden>
      </Button>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Filter9Plus className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              가맹 리그
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={() => gotoGameonePage("931")}
                      className={classes.dropdownItem}
                    >
                      챔프베이스볼리그
                    </MenuItem>
                    <MenuItem
                      onClick={() => gotoGameonePage("761")}
                      className={classes.dropdownItem}
                    >
                      수원베이스볼리그
                    </MenuItem>
                    <MenuItem
                      onClick={() => gotoGameonePage("10663")}
                      className={classes.dropdownItem}
                    >
                      M.C베이스볼리그
                    </MenuItem>
                    <MenuItem
                      onClick={() => gotoGameonePage("304")}
                      className={classes.dropdownItem}
                    >
                      TS리그
                    </MenuItem>
                    <MenuItem
                      onClick={() => gotoGameonePage("10255")}
                      className={classes.dropdownItem}
                    >
                      원천리그
                    </MenuItem>
                    <MenuItem
                      onClick={() => gotoGameonePage("252")}
                      className={classes.dropdownItem}
                    >
                      명품BB리그
                    </MenuItem>
                    <MenuItem
                      onClick={() => gotoGameonePage("10311")}
                      className={classes.dropdownItem}
                    >
                      파워리그
                    </MenuItem>
                    <MenuItem
                      onClick={() => gotoGameonePage("10101")}
                      className={classes.dropdownItem}
                    >
                      경희 빅리그
                    </MenuItem>
                    <MenuItem
                      onClick={() => gotoGameonePage("10509")}
                      className={classes.dropdownItem}
                    >
                      나노리그
                    </MenuItem>
                    <MenuItem
                      onClick={() => gotoGameonePage("10719")}
                      className={classes.dropdownItem}
                    >
                      유신 워너비 리그
                    </MenuItem>
                    <MenuItem
                      onClick={() => gotoGameonePage("107")}
                      className={classes.dropdownItem}
                    >
                      화성 리그
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <PersonOutlineIcon className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>프로필</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={viewProfile}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>
                    {/*
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Settings
                    </MenuItem>
                    */}
                    <Divider light />
                    <MenuItem
                      onClick={logOut}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
