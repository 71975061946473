import React, { useState, useEffect } from 'react';
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import * as config from '../../config.js'

export default function ApplyEndDialog(props) {

  const [youtubeLink, setYoutubeLink] = useState('');

  const handleCommit = () => {
    console.log(props.applyEndIndex);
    const access_token = localStorage.getItem('access_token');

    const url = config.API_SERVER_URI + "/autho/update/apply/end"
    const thisUserList = []
    axios.post(url, {
      apply_index : props.applyEndIndex,
      youtube_link : youtubeLink,
      phone_number : props.applyIndexDict[props.applyEndIndex]['apply_depositor_phone_number']
    },{
      headers : {
        'Authorization' : 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.log(error);
    }).then(function() {
      props.handleApplyEndDialogClose();
      props.LoadApplys();
    });
  };

  const handleYoutubeLinkChange = (event) =>{
    setYoutubeLink(event.target.value);
  }

  return (
    <Dialog
      open={props.applyEndDialogOpen}
      onClose={props.handleApplyEndDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">입금 확인 처리</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <GridContainer>
            <GridItem xs={12}>
              <TextField
                margin="dense"
                id="link"
                label="유튜브 링크"
                type="text"
                value={youtubeLink}
                onChange={handleYoutubeLinkChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12}>
              {props.applyIndexDict[props.applyEndIndex] != undefined &&
                  <div><Box fontWeight="fontWeightBold">{props.applyIndexDict[props.applyEndIndex]['apply_depositor_name']}</Box>님에게 해당 링크를 전송합니다.</div>}
            </GridItem>
          </GridContainer>

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleApplyEndDialogClose} color="primary">
          취소
        </Button>
        <Button onClick={handleCommit} color="primary" autoFocus>
          확인
        </Button>
      </DialogActions>
      </Dialog>
  );
}
