import React, { useState, useEffect } from 'react';
import axios from 'axios';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
// import { ResponsiveBar } from '@nivo/bar'
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import GroupIcon from '@material-ui/icons/Group';
import Assignment from '@material-ui/icons/Assignment';
import Store from "@material-ui/icons/Store";
import DateRange from "@material-ui/icons/DateRange";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as config from '../../config.js'

import {
  dailyYoutubeChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const options = {
  title: {
    text: 'My stock chart'
  },
  series: [
    {
      data: [1, 2, 1, 4, 3, 6, 7, 3, 8, 6, 9]
    }
  ]
};
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();

  const [totalPlayCount, setTotalPlayCount] = useState(0);
  const [totalApplyCount, setTotalApplyCount] = useState(0);
  const [totalEstimatedMinute, setTotalEstimatedMinute] = useState(0);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [youtubeReportDates, setYoutubeReportDates] = useState([])
  const [youtubeReportViews, setYoutubeReportViews] = useState([])
  const [youtubeReportLikes, setYoutubeReportLikes] = useState([])
  const [youtubeReportShares, setYoutubeReportShares] = useState([])
  const [playCountByFields , setPlayCountByFields] = useState([]);
  const [applyRankList , setApplyRankList] = useState([]);

  const [monthlyApplyDate, setMonthlyApplyDate] = useState([]);
  const [monthlyApplyCount, setMonthlyApplyCount] = useState([]);

  const [fieldMonthCategories, setFieldMonthCategories] = useState([]);
  const [fieldMonthCount, setFieldMonthCount] = useState([]);

  const makeNumberDot = (target_number) =>{
    return target_number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
  }

  const tokenRefresh = () => {
    const refresh_token = localStorage.getItem('refresh_token');
    const url = config.API_SERVER_URI + "/reissueToken";

    axios.post(url, {},{
      headers : {
        'Authorization' : 'Bearer ' + refresh_token
      }
    })
    .then(function (response) {
      if(response.data.response == true){
        //토큰 업데이트
        localStorage.setItem('access_token',response.data.accesss_token);
        if(response.data.refresh_token){
          localStorage.setItem('refresh_token',response.data.refresh_token);
        }
      }
      else{
        alert("재로그인이 필요합니다.");
        window.location.href="/login"
      }

    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
    });
  }
  useEffect(() => {

    const access_token = localStorage.getItem('access_token');
    if(access_token == null || access_token == undefined){
      alert("로그인이 필요합니다.")
      window.location.href="/login"
    }
    else{
      tokenRefresh();
    }

    //경기수, 유저, 시청시간 등이 포함된 정보 가져오기
    var url = config.API_SERVER_URI + "/select/sum"
    axios.post(url, {
    })
    .then(function (response) {
        console.log(response.data);
        setTotalPlayCount(makeNumberDot(response.data.count_play_index));
        setTotalApplyCount(makeNumberDot(response.data.count_apply_index));
        setTotalEstimatedMinute(makeNumberDot(response.data.sum_estimated_minutes_watched));
        setTotalUserCount(makeNumberDot(response.data.count_member_index))
    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
    });

    // 월별 개인영상 신청 수 가져오기
    var url = config.API_SERVER_URI + "/select/monthlyApplyCount";
    axios.post(url, {
    })
    .then(function (response) {
      console.log("월별 개인");

      var rows = response.data;
      console.log(rows);

      var ad = [];
      var ac = [];

      for (var i = 0; i < rows.length; i++){
        ad.push(rows[i]['ym']);
        ac.push(rows[i]['applyCount']);
      }

      setMonthlyApplyDate(ad);
      setMonthlyApplyCount(ac);

    }).catch(function (err) {
      console.log(err);
    }).then(function(){

    });

    //유튜브 일주일데이터 가져오기
    var thisViewsList = []
    var thisLikesList = []
    var thisSharesList = []
    var thisDatesList = []
    var url = config.API_SERVER_URI + "/select/youtube/all"
    axios.post(url, {
    })
    .then(function (response) {
        console.log(response.data);
        for(var index in response.data){
          const this_data = response.data[index];
          var this_date_split = this_data['report_input_date'].split('-')
          var this_date = this_date_split[0].trim().substr(2,2)+"/"+this_date_split[1]+"/"+this_date_split[2]

          thisDatesList.push(this_date)
          thisViewsList.push(this_data['report_views'])
          thisLikesList.push(this_data['report_likes'])
          thisSharesList.push(this_data['report_shares'])
        }

    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
        setYoutubeReportDates(thisDatesList);
        setYoutubeReportViews(thisViewsList);
        setYoutubeReportLikes(thisLikesList);
        setYoutubeReportShares(thisSharesList);
    });

    var url = config.API_SERVER_URI + "/select/play/field"
    var thisPlayCountByFields = []
    var fields = []
    axios.post(url, {
    })
    .then(function (response) {
        console.log("테스트1");
        console.log(response.data);
        for(var index in response.data){
          const this_data = response.data[index];
          thisPlayCountByFields.push([this_data['play_field'],this_data['count']])
          fields.push({name:this_data['play_field'], data:[]});
        }

    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
        setPlayCountByFields(thisPlayCountByFields);
        //setFieldMonthCategories(fieldMonthCate);
    });

    var url = config.API_SERVER_URI + "/select/apply/rank"
    var thisApplyRank = []
    var count = 1
    axios.post(url, {
    })
    .then(function (response) {
        console.log(response.data);
        for(var index in response.data){
          const this_data = response.data[index];
          thisApplyRank.push([count, this_data['apply_depositor_name'], makeNumberDot(this_data['total_price']) +"원", this_data['total_count'] +"개"])
          count += 1
        }

    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
        setApplyRankList(thisApplyRank);
    });

    // 경기장별 월별
    var url = config.API_SERVER_URI + "/select/play/fieldMonth";
    var yearMonth = new Set();
    var yearMonthOrder = {};
    var thisFieldMonthCount = {};
    var count = 1;
    axios.post(url, {
    })
    .then(function (response) {
        console.log("경기장별 월별");
        console.log(response.data);

        for (var i in response.data) {
          yearMonth.add(response.data[i]['ym']);
        }
        var count = 0;
        for (let i of yearMonth){
          yearMonthOrder[i] = count;
          count++;
        }
        //console.log(yearMonth);

        for (var i in fields) {
          //console.log(fields[i]['name'])
          thisFieldMonthCount[fields[i]['name']] = []
          for (let j of yearMonth) {
            //console.log(j);
            thisFieldMonthCount[fields[i]['name']].push(0);
          }
        }

        for (var i in response.data) {
          var now = yearMonthOrder[response.data[i]['ym']];
          var field = response.data[i]['play_field'];
          var count = response.data[i]['playCount'];

          thisFieldMonthCount[field][now] = count;
        }

        for (var i in thisFieldMonthCount) {
          for (var j in fields) {
            if (fields[j]['name'] == i){
              fields[j]['data'] = thisFieldMonthCount[i];
              break;
            }
          }
        }

        console.log(thisFieldMonthCount)

    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
        //setApplyRankList(thisApplyRank);
        setFieldMonthCategories(Array.from(yearMonth));
        setFieldMonthCount(fields);
    });

  }, [])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Assignment />
              </CardIcon>
              <p className={classes.cardCategory}>총 경기 수</p>
              <h3 className={classes.cardTitle}>
                {totalPlayCount} <small>경기</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                리그, 용병 경기 수
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>개인영상 신청 수</p>
              <h3 className={classes.cardTitle}>
                {totalApplyCount}<small>개</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                개인영상을 신청한 숫자
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <AccessAlarmIcon />
              </CardIcon>
              <p className={classes.cardCategory}>시청 시간</p>
              <h3 className={classes.cardTitle}>
                {totalEstimatedMinute} <small>분</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                유튜브 예측된 시청 시간
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <GroupIcon />
              </CardIcon>
              <p className={classes.cardCategory}>유저수</p>
              <h3 className={classes.cardTitle}>
                {totalUserCount}<small>명</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                유니크 플레이 회원 수
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger">
              <h4 className={classes.cardTitleWhite}>유튜브 리포트</h4>
              <p className={classes.cardCategoryWhite}>
                서비스 시작부터 영상 뷰, 좋아요, 공유 수
              </p>
            </CardHeader>
            <CardBody>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                      zoomType : 'x'
                    },
                    title: {
                        text: ''
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle'
                    },
                    xAxis: {
                        categories: youtubeReportDates
                    },

                    series: [{
                        name: 'Views',
                        data: youtubeReportViews
                    }
                    , {
                        name: 'Likes',
                        data: youtubeReportLikes
                    }, {
                        name: 'Shares',
                        data: youtubeReportShares
                    }],

                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom'
                                }
                            }
                        }]
                    }

                }}
              />
            </CardBody>

          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="danger">
              <h4 className={classes.cardTitleWhite}>월별 개인영상 신청 수</h4>
              <p className={classes.cardCategoryWhite}>
                월별 개인영상 신청 수
              </p>
            </CardHeader>
            <CardBody>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                      type : "column",
                      zoomType : 'x'
                    },
                    title: {
                        text: ''
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle'
                    },
                    xAxis: {
                        categories: monthlyApplyDate
                    },

                    series: [{
                        name: '신청수',
                        data: monthlyApplyCount
                    }
                    ],
                    plotOptions: {
                        series: {
                            stacking: 'normal',
                            dataLabels: {
                                enabled: true,
                                format: "<b>{point.y}</b>",
                            }
                        }
                    },
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'vertical',
                                    align: 'center',
                                    verticalAlign: 'bottom'
                                }
                            }
                        }]
                    }

                }}
              />
            </CardBody>

          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>개인영상 신청 TOP 5</h4>
              <p className={classes.cardCategoryWhite}>
                지금까지 개인영상 신청한 사람 금액 순 TOP 5
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["Top", "이름", " 신청금액", "신청수"]}
                tableData={applyRankList}
              />
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>경기장 별 월별 경기 수</h4>
              <p className={classes.cardCategoryWhite}>
                경기장 별 월별 경기 수
              </p>
            </CardHeader>
            <CardBody>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                      type: 'bar',
                      zoomType: 'x'
                  },
                  title: {
                      text: ''
                  },
                  xAxis: {
                      categories: fieldMonthCategories
                  },
                  yAxis: {
                      min: 0,
                      title: {
                          text: ''
                      }
                  },
                  legend: {
                      reversed: true
                  },
                  plotOptions: {
                      series: {
                          stacking: 'normal'
                      }
                  },
                  series: fieldMonthCount
                }}
              />
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
