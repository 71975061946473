import React from 'react';
import axios from "axios";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import * as config from '../../config.js'

export default function HomerunEndDialog(props) {

  const handleCommit = () => {
    console.log(props.playEndIndex);
    const access_token = localStorage.getItem('access_token');

    const url = config.API_SERVER_URI + "/autho/update/homerun"
    const thisUserList = []
    axios.post(url, {
      homerun_index : props.homerunEndIndex
    },{
      headers : {
        'Authorization' : 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.log(error);
    }).then(function() {
      props.handleHomerunEndDialogClose();
      props.LoadHomeruns();
    });
  };

  return (
    <Dialog
      open={props.homerunEndDialogOpen}
      onClose={props.handleHomerunEndDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">홈런 완료 처리</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <GridContainer>
            <GridItem xs={12}>
              {props.homerunIndexDict[props.homerunEndIndex] != undefined &&
                <div><Box fontWeight="fontWeightBold">{props.homerunIndexDict[props.homerunEndIndex]['homerun_user_name']}</Box> 님의 홈런을 완료 처리하시겠습니까?</div>}
            </GridItem>
          </GridContainer>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleHomerunEndDialogClose} color="primary">
          취소
        </Button>
        <Button onClick={handleCommit} color="primary" autoFocus>
          완료
        </Button>
      </DialogActions>
      </Dialog>
  );
}
